.optin {
  margin-top: 0px;
}
.optin p {
  font-size: 14px;
  font-weight: 100;
}
.optin button {
  color: #fff;
  padding: 6px 22px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  background-color: rgba(255, 255, 255, 0.1);
  border: solid 2px transparent;
  cursor: pointer;
  margin-top: 10px;
}
.optin button:hover {
  border-color: rgba(255, 255, 255, 0.3);
}
.optin input {
  width: 300px;
  height: 1.2rem;
  padding: 7px 15px;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
}

.errorMessage {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  color: red;
  font-weight: bold;
  font-size: 11px;
}

input[type="email"]:focus {
  border: none;
}

#modal {
  position: fixed;
  top: 100px;
  left: 30%;
  width: 500px;
  background: #fff;
  transition: opacity 1s;
  box-shadow: 0 7px 2px #444;
  z-index: 99999;
  opacity: 0;
}

#modal .wrapper {
  color: #444;
  text-align: center;
  padding: 30px;
}
